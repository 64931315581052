import React from 'react'
import { Flex } from '@clear/design-system'

const Logo = () => (
  <Flex alignItems="center" width={['250px', null, '337px']}>
    <img
      style={{ minWidth: '0', marginRight: '30px' }}
      src="/tsa-logo.png"
      alt="TSA logo"
    />
    <img
      style={{ minWidth: '0' }}
      src="/header-clear-logo.svg"
      alt="TSA pre-check logo"
    />
  </Flex>
)

export default Logo
