import React, { SetStateAction } from 'react'
import useMedia from 'use-media'
import { Flex, Box, Link, Icon, theme } from '@clear/design-system'
import { GovernmentSecurityBanner } from '@components/storybook'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { setAccessToken, setSessionId } from '@api/utils'

type Link = {
  text: string
  href: string
  mobileOnly?: boolean
  id?: string
}

export interface HeaderMenuProps {
  links: Array<Link>
  router: any
  authenticated: boolean
  menuRef: React.Ref<HTMLDivElement>
  toggleMenu: () => void
  clearState: () => void
}

const boxShadow = '1px 0px 21px rgba(0, 0, 0, 0.12)'

const HeaderMenu = ({
  links,
  router,
  authenticated,
  menuRef,
  toggleMenu,
  clearState,
}: HeaderMenuProps) => (
  <Flex
    data-testid="header-menu"
    flexDirection="column"
    position="absolute"
    right={[0, null, 5]}
    top={['100%', null, 'calc(100% - 24px)']}
    zIndex={9999}
    p={5}
    backgroundColor="neutralsWhite"
    width={['100%', null, '316px']}
    sx={{
      boxShadow,
    }}
    ref={menuRef}
  >
    {links.map(({ text, href, mobileOnly, id }: Link, index) => (
      <NextLink key={`header-link-${index}`} href={href} passHref>
        <Link
          onClick={() => toggleMenu()}
          mb={[index < links.length - 1 ? 3 : 0, 3]}
          mr={[0, 7]}
          display={[null, null, mobileOnly ? 'none' : 'block']}
          fontSize={4}
          id={id}
          fontWeight="medium"
          color="groundDarkStone"
          variant="regular"
          sx={{
            py: 4,
            textAlign: 'left',
            textTransform: 'uppercase',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: 'neutralsBackgroundGray',
            ':first-child': {
              paddingTop: 0,
            },
            ':last-child': {
              paddingBottom: 0,
              borderBottom: 'none',
            },
          }}
        >
          {text}
        </Link>
      </NextLink>
    ))}
    {!authenticated && (
      <Link
        mb={[0, 3]}
        mr={[0, 7]}
        display={[null, null, 'block']}
        fontSize={4}
        fontWeight="medium"
        onClick={() => {
          toggleMenu()
          router.push({
            pathname: '/auth',
          })
        }}
        color="groundDarkStone"
        variant="regular"
        sx={{
          py: 4,
          cursor: 'pointer',
          textAlign: 'left',
          textTransform: 'uppercase',
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: 'neutralsBackgroundGray',
          ':first-child': {
            paddingTop: 0,
          },
          ':last-child': {
            paddingBottom: 0,
            borderBottom: 'none',
          },
        }}
      >
        Log In
      </Link>
    )}

    {authenticated && (
      <Link
        mb={[0, 3]}
        mr={[0, 7]}
        display={[null, null, 'block']}
        fontSize={4}
        fontWeight="medium"
        onClick={() => {
          clearState()
          setAccessToken('')
          setSessionId('')
          toggleMenu()
          router.push('/')
        }}
        variant="regular"
        sx={{
          py: 4,
          cursor: 'pointer',
          textAlign: 'left',
          textTransform: 'uppercase',
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: 'neutralsBackgroundGray',
          ':first-child': {
            paddingTop: 0,
          },
          ':last-child': {
            paddingBottom: 0,
            borderBottom: 'none',
          },
        }}
      >
        Log Out
      </Link>
    )}
  </Flex>
)

export interface HeaderProps {
  links: Array<Link>
  dropdownLinks: Array<Link>
  logo: JSX.Element
  authenticated: boolean
  isMenuOpen: boolean
  setIsMenuOpen: React.Dispatch<SetStateAction<boolean>>
  menuRef: React.Ref<HTMLDivElement>
  clearState: () => void
}

export const Header = ({
  links,
  dropdownLinks,
  logo,
  authenticated,
  isMenuOpen,
  setIsMenuOpen,
  menuRef,
  clearState,
}: HeaderProps) => {
  const isDesktop = useMedia({
    minWidth: theme?.breakpoints?.[1] as string,
  })
  const router = useRouter()
  const visibleDropdownLinks = dropdownLinks.filter(
    (link) => !link.mobileOnly || (!isDesktop && link.mobileOnly)
  )

  return (
    <Box as="header">
      <GovernmentSecurityBanner />
      <Flex
        backgroundColor="neutralsWhite"
        justifyContent="center"
        sx={{
          boxShadow,
        }}
      >
        <Flex
          p={[4, null, 5]}
          justifyContent="space-between"
          alignItems="center"
          maxWidth="100%"
          width={['100%', 'header']}
          position="relative"
        >
          {logo}
          <Flex alignItems="center">
            {links && (
              <Box display={['none', null, 'block']}>
                {links.map((link) => (
                  <NextLink key={link.text} href={link.href} passHref>
                    <Link
                      fontSize={4}
                      fontWeight="700"
                      variant="regular"
                      px={5}
                      sx={{
                        textTransform: 'uppercase',
                      }}
                      color="groundDarkStone"
                    >
                      {link.text}
                    </Link>
                  </NextLink>
                ))}
              </Box>
            )}
            {visibleDropdownLinks && (
              <>
                <Box
                  as="button"
                  data-testid="header-menu-toggle"
                  aria-label={isMenuOpen ? 'close menu' : 'open menu'}
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  sx={{
                    padding: 0,
                    border: 'none',
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                    ml: 4,
                  }}
                >
                  <Icon
                    name={isMenuOpen ? 'x' : 'menu'}
                    size="2rem"
                    color="groundDarkStone"
                  />
                </Box>
                {isMenuOpen && (
                  <HeaderMenu
                    authenticated={authenticated}
                    router={router}
                    links={visibleDropdownLinks}
                    menuRef={menuRef}
                    toggleMenu={() => setIsMenuOpen(!isMenuOpen)}
                    clearState={clearState}
                  />
                )}
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}

Header.displayName = 'Header'
export default Header
