export const footerArgs = {
  links: [
    {
      text: 'Terms',
      href: '/terms',
      id: 'footer-terms',
    },

    {
      text: 'About',
      href: '/about',
      id: 'footer-about',
    },
    {
      text: 'Contact Us',
      href: '/contact-us',
      id: 'footer-contact-us',
    },
    {
      text: 'Important Notices',
      href: '/important-notices',
      id: 'footer-important-notices',
    },
  ],
  texts: [
    {
      text: 'Transportation Security Administration (TSA)',
      href: 'https://www.tsa.gov/',
    },
    {
      text: 'U.S. Department of Homeland Security (DHS)',
      href: 'https://www.dhs.gov/',
    },
    {
      text: 'TSA Privacy Act Statement',
      href: 'https://www.tsa.gov/privacy-policy',
    },
    {
      text: 'Freedom of Information Act',
      href: 'https://www.dhs.gov/freedom-information-act-foia',
    },
  ],
}

if (process.env.NEXT_PUBLIC_HIDE_ENROLLMENT_FLOW === 'true') {
  const linksIndex = footerArgs.links.findIndex((link) => link.text === 'About')
  if (linksIndex !== -1) {
    footerArgs.links.splice(linksIndex, 1)
  }
}
